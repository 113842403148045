.bgLogin {
    margin:0;
    padding:0;
    background: url(./../../../img/ident/bg.jpg) no-repeat center fixed;
    -webkit-background-size: cover; /* pour anciens Chrome et Safari */
    background-size: cover; /* version standardisée */
    height: 100%;
}
#root {
    height: 100%;
}

.loginScreen {
    background-color: white;
    height: 100%;
}
.formIdent {
    padding: 10px;
}

.fullHeight {
    height: 100%;
}