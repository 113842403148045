.contentStep {
    padding: 30px;
}

.sideBar {
    width: 80%;
    margin: 0 auto;
}

.center {
    text-align: center;
}

.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mb10 {
    margin-bottom: 10px;
}

.carType {
    padding: 5px;
    border-right: #282c34 dashed 1px;
}

.imgSejour {
    width: 80%;
}

.dateChoice {
    padding: 35px;
    border: #81014C solid 1px;
    margin-top: 40px;
}

.uploadDocs {
    margin: 0 auto;
    width: auto;
}

.loginButonFacebook {
    background-color: #2f54eb;
    font-size: 14px;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

h1 {
    color: #db3056 !important;
}

h2,h3,h4 {
    color: #db3056 !important;
}

.ant-btn-primary {
    background: #db3056;
    border-color: #db3056;
}

.ant-switch-checked {
    background-color: #db3056;
}

.ant-steps-item-process .ant-steps-item-icon {
    background: #db3056;
    border-color: #db3056;
}

#RecapScreenMobile {
    display: none;
}

@media screen and (max-width: 990px) {
    #stepScreen {
        display: none;
    }

    #RecapScreen {
        display: none;
    }

    #RecapScreenMobile {
        display: block;
    }
}

