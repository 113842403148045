.iconBox {
font-size: 50px;
}
.centerTxt {
    text-align: center;
}

.boxAccess {

}

.contentBox {
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    width: 95%;
}

#root > section > aside {
    background-color: white;
}
section,.ant-layout-sider {
    background-color: white!important;
}