.site-page-header {
    border: 1px solid rgb(235, 237, 240);
}
.headerImg {
    min-height: 300px;
    background-image: url("./../../../img/order/bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.center {
    text-align: center;
}
.ant-page-header-ghost {
    background-color: white;
}

.projet {
    background-color: white;
    width: 80%;
    padding: 20px;
    margin-left: 50px;

}
